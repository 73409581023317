import React, { useState } from 'react'
import { DetailsItem, DetailsClient, DetailsRoot, Root, RootButtons, RootForm, RootTitle, TimeContainer, ListModalRoot } from './CreateTask.style'
import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import { Avatar, Box, Button, Card, Grid, Menu, MenuItem, Modal, TextField, Typography } from '@mui/material'
import CardItem from '@/components/Cards/CardItem/CardItem'
import CardExpand from '@/components/Cards/CardExpand/CardExpand'
import { MultiSectionDigitalClock } from '@mui/x-date-pickers'
import moment from 'moment'
import ClientModal from '../../modules/task/Header/Modal/ClientModal/ClientModal'
import UserModal from '@/modules/task/Header/Modal/UserModal/UserModal'
import ListModal from '@/modules/task/Header/Modal/ListModal/ListModal'
import CalendarModal from '@/components/CalendarModal/CalendarModal'

const CreateTask = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openCalendarModal, setOpenCalendarModal] = useState(false)
  const [openTimeModal, setOpenTimeModal] = useState(false)
  const [openClientModal, setOpenClientModal] = useState(false)
  const [openUserModal, setOpenUserModal] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  //Form
  const [title, setTitle] = useState(null)
  const [note, setNote] = useState(null)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [client, setClient] = useState(null)
  const [clientName, setClientName] = useState(null)
  const [user, setUser] = useState(null)
  const [userName, setUserName] = useState(null)
  const [priority, setPriority] = useState(null)
  const [listName, setListName] = useState(null)

  const handleClickMenuPriority = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenuPriority = (value) => {
    value && setPriority(value)
    setMenuAnchorEl(null);
  };
  return (
    <Root>
      <CalendarModal
        date={date}
        setDate={setDate}
        open={openCalendarModal}
        setOpen={setOpenCalendarModal} />
      <TimeModal
        time={time}
        setTime={setTime}
        open={openTimeModal}
        setOpen={setOpenTimeModal} />
      <ClientModal
        value={client}
        setValue={setClient}
        open={openClientModal}
        setOpen={setOpenClientModal}
        setName={setClientName}
      />
      <UserModal
        value={user}
        setValue={setUser}
        open={openUserModal}
        setOpen={setOpenUserModal}
        setName={setUserName}
      />
      <ListModal
        value={user}
        setValue={setUser}
        setListName={setListName}
        open={openModal}
        setOpen={setOpenModal}
      />
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenuPriority}
      >
        <MenuItem onClick={() => handleCloseMenuPriority(1)}>Baja</MenuItem>
        <MenuItem onClick={() => handleCloseMenuPriority(2)}>Media</MenuItem>
        <MenuItem onClick={() => handleCloseMenuPriority(3)}>Alta</MenuItem>
      </Menu>
      <MenuHeader
        goBack
        goBackTo={'/task'}
        right={() => { }}
        title='Crear Tarea'
      />
      <RootForm>
        <Card>
          <Grid style={{ padding: 20 }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              <TextField
                placeholder='Título'
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder='Notas'
                fullWidth
                maxRows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CardExpand title='Detalles'>
                <Details
                  handleClickMenuPriority={handleClickMenuPriority}
                  setOpenCalendarModal={setOpenCalendarModal}
                  setOpenTimeModal={setOpenTimeModal}
                  setOpenClientModal={setOpenClientModal}
                  setOpenUserModal={setOpenUserModal}
                  time={time}
                  date={date}
                  client={client}
                  clientName={clientName}
                  user={user}
                  userName={userName}
                  priority={priority}
                />
              </CardExpand>
            </Grid>
            <Grid item xs={12}>
              <div onClick={() => setOpenModal(true)}>
                <CardItem
                  title='Lista'
                  rightElement={
                    <Typography style={{ marginRight: 10 }}>{listName}</Typography>}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant='contained'>Crear tarea</Button>
            </Grid>
          </Grid>
        </Card>
      </RootForm>
    </Root>
  )
}

const Details = ({
  handleClickMenuPriority,
  setOpenCalendarModal,
  setOpenTimeModal,
  setOpenClientModal,
  setOpenUserModal,
  date,
  time,
  client,
  clientName,
  user,
  userName,
  priority
}) => {
  return (
    <DetailsRoot>
      <DetailsItem onClick={() => setOpenCalendarModal(true)}>
        <CardItem
          title={'Fecha'}
          rightElement={date ? <Typography style={{ marginRight: 5 }} variant='body2'>{moment(date).format('DD/MM/YYYY')}</Typography> : null} />
      </DetailsItem>
      <DetailsItem onClick={() => setOpenTimeModal(true)}>
        <CardItem
          title={'Hora'}
          rightElement={time ? <Typography style={{ marginRight: 5 }} variant='body2'>{moment(time).format('HH:mm')}</Typography> : null} />
      </DetailsItem>
      <DetailsItem onClick={() => setOpenClientModal(true)}>
        <CardItem
          title={'Cliente'}
          rightElement={client ?
            <DetailsClient>
              <Avatar style={{ width: 30, height: 30, marginRight: 20 }} />
              <Typography style={{ marginRight: 5 }} variant='body2'>{clientName}</Typography>
            </DetailsClient>
            : null}
        />
      </DetailsItem>
      <DetailsItem onClick={() => setOpenUserModal(true)}>
        <CardItem
          title={'Responsable'}
          rightElement={user ?
            <DetailsClient>
              <Avatar style={{ width: 30, height: 30, marginRight: 20 }} />
              <Typography style={{ marginRight: 5 }} variant='body2'>{userName}</Typography>
            </DetailsClient>
            : null}
        />
      </DetailsItem>
      <DetailsItem onClick={handleClickMenuPriority}>
        <CardItem
          title={'Prioridad'}
          rightElement={
            priority !== null
            && <Typography variant='body1' style={{ marginRight: 20 }}>
              {priority === 1 ? 'Baja' : (priority === 2 ? 'Media' : 'Alta')}
            </Typography>
          }
        />
      </DetailsItem>
      <DetailsItem>
        <CardItem title={'Story Points'}
          rightElement={<TextField variant='standard' style={{ maxWidth: 30, marginRight: 20 }}></TextField>}
        />
      </DetailsItem>
    </DetailsRoot>
  )
}

const TimeModal = ({ time, setTime, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
    >
      <ListModalRoot>
        <Card>
          <Box padding={3}>
            <RootTitle>
              <Typography variant='h6' style={{ marginBottom: 50 }}>Tiempo</Typography>
              {time && <Typography variant='h6' style={{ marginBottom: 50 }}>{moment(time).format('HH:mm')}</Typography>}
            </RootTitle>
            <TimeContainer>
              <MultiSectionDigitalClock value={time} onChange={(val) => setTime(val)} />
            </TimeContainer>
            <RootButtons>
              <Button onClick={() => setOpen(false)}>
                Cerrar
              </Button>
            </RootButtons>
          </Box>
        </Card>
      </ListModalRoot>
    </Modal>
  )
}

export default CreateTask