import MenuHeader from '@/layout/MenuHeader/MenuHeader'
import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Button, Grid, MenuItem, Snackbar, TextField, Typography } from '@mui/material';
import { Root, RootForm } from './CreateClient.style';
import uploadImg from '@/api/customer/uploadImg';
import uploadImgCompany from '@/api/company/uploadImg';
import create from '@/api/customer/create';
import { useNavigate, useSearchParams } from 'react-router-dom';
import getOne from '@/api/preCustomer/getOne';
import get from '@/api/product/get';
import Card from '@/components/Cards/Card/Card';
// import InputPayments from '@/modules/create_client/InputPayments/InputPayments';

const CreateClient = () => {
  const [listStatus, setListStatus] = useState([])
  const [error, setError] = useState(null)
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState('')
  const [productList, setProductList] = useState(null)

  const [img, setImg] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [companyTitle, setCompanyTitle] = useState(null)
  const [companyInstagram, setCompanyInstagram] = useState(null)
  const [companyImg, setComanyImg] = useState(null)
  const [reference, setReference] = useState(null)
  const [comment, setComment] = useState(null)
  const [products, setProducts] = useState(null)

  const [searchParams] = useSearchParams();
  const preClientId = searchParams.get('preclientid')

  const handleGetPreClient = async () => {
    const res = await getOne(preClientId)
    if (!res) {
      return alert('Error al obtener posible cliente')
    }
    if (!res.data) {
      return alert('Error al obtener posible cliente 01')
    }
    setName(res.data.name)
    setPhone(res.data.phone)
    setCompanyInstagram(res.data.instagram)
    setEmail(res.data.email)
    setReference(res.data.reference)
    setComment(res.data.comment)

    const send = []
    res.data.products.map((i: any) => {
      send.push({
        product: i.product.id,
        description: i.description
      })
    })
    console.log("send", send)
    setProducts(send)

    console.log("handleGetPreClient", res)
  }

  useEffect(() => {
    if (preClientId && productList) {
      handleGetPreClient()
    }
  }, [productList])

  const navigate = useNavigate()

  const createClient = async () => {
    setError('')
    let req: any = {
      name,
      phone,
      email,
      companyTitle,
      companyInstagram,
      products,
      comment,
      reference,
    }
    if (img) {
      const res = await uploadImageUrl(img)
      if (!res) { return null }
      req.img = res
    }
    if (companyImg) {
      const res = await uploadImageCompanyUrl(companyImg)
      if (!res) { return null }
      req.companyImg = res
    }
    if (preClientId) {
      req.preClientId = preClientId
    }
    setLoad(true)
    const res = await create(req)
    console.log("create", res)
    setLoad(false)
    if (!res) {
      return setError('Error al crear cliente')
    }
    setMessage('¡Cliente  creado con éxito!')
    empityState()
    navigate('/client')
  }

  const fileInput = useRef(null)
  const empityState = () => {
    fileInput.current.value = ''
    setImg(null)
    setName('')
    setPhone('')
    setEmail('')
    setComanyImg('')
    setCompanyInstagram('')
    setCompanyTitle('')
    setReference('')
    setComment('')
  }


  const uploadImageUrl = async (objectImage) => {
    setLoad(true)
    const res = await uploadImg(objectImage)
    setLoad(false)
    if (!res) {
      setError('Error al cargar imagen Cliente')
      return null
    }
    if (!res.data) {
      if (res.error) {
        setError(res.error.errorMessage + ' IMAGEN CLIENTE')
        return null
      } else {
        setError('Error al cargar imagen Cliente')
        return null
      }
    }
    return res.data.url
  }

  const uploadImageCompanyUrl = async (objectImage) => {
    setLoad(true)
    const res = await uploadImgCompany(objectImage)
    setLoad(false)
    if (!res) {
      setError('Error al cargar imagen Empresa')
      return null
    }
    if (!res.data) {
      if (res.error) {
        setError(res.error.errorMessage + ' IMAGEN EMPRESA')
        return null
      } else {
        setError('Error al cargar imagen Empresa')
        return null
      }
    }
    return res.data.url
  }

  const onSubmit = () => {
    setError('')
    createClient()
  }

  const handleGetProducts = async () => {
    const res = await get()
    if (!res) {
      return console.log('Error al obtener productos')
    }
    setProductList(res.data)

    if (!products) {
      setProducts([{
        product: null,
        description: ''
      }])
    }
  }

  useEffect(() => {
    void handleGetProducts()
  }, [])

  const uploadFileImg = event => {
    if (event.target.files && event.target.files[0]) {
      const i = event.target.files[0]
      setImg(i)
    }
  }
  const uploadFileCompanyImg = event => {
    if (event.target.files && event.target.files[0]) {
      const i = event.target.files[0]
      setComanyImg(i)
    }
  }

  const handleAddProductInput = () => {
    const state = Object.assign([], products)
    state.push({
      product: null,
      description: ''
    })
    setProducts(state)
  }
  const handleRemoveProductInput = () => {
    const state = Object.assign([], products)
    state.pop()
    setProducts(state)
  }
  const onChangeDescription = (key, value) => {
    const state = Object.assign([], products)
    state[key].description = value
    setProducts(state)
  }
  const onChangeProductId = (key, value) => {
    const state = Object.assign([], products)
    state[key].product = value
    setProducts(state)
  }

  return (
    <Root>
      <MenuHeader
        goBack
        right={() => { }}
        title='Agregar Cliente'
      />
      <RootForm>
        <Card>
          <Grid style={{
            padding: 20
          }} container spacing={4} alignItems={'center'}>
            <Grid item xs={12}>
              {
                products
                && products.map((itemProduct, key) => (
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Servicio"
                          select
                          variant='outlined'
                          fullWidth
                          value={itemProduct.product}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => onChangeProductId(key, e.target.value)}
                        >
                          {
                            productList && productList.map(item => (
                              <MenuItem value={item.id}>{item.title}</MenuItem>
                            ))
                          }
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          onChange={(e) => onChangeDescription(key, e.target.value)}
                          placeholder='Descripción servicio'
                          fullWidth
                          value={itemProduct.description}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                ))
              }
              {products && products.length > 1
                && <Button onClick={handleRemoveProductInput} variant='contained' color="secondary">Borrar último</Button>}
              <Button style={{ marginTop: 10 }} onClick={handleAddProductInput} variant='contained'>Agregar servicio</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography>Foto Cliente</Typography>
            </Grid>
            <Grid item xs={2}>
              <Avatar
                src={img && URL.createObjectURL(img) ? URL.createObjectURL(img) : null}
              />
            </Grid>
            <Grid item xs={10}>
              <input
                onChange={uploadFileImg}
                type='file' />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Nombre cliente'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Foto de Empresa</Typography>
            </Grid>
            <Grid item xs={2}>
              <Avatar
                src={companyImg && URL.createObjectURL(companyImg) ? URL.createObjectURL(companyImg) : null}
              />
            </Grid>
            <Grid item xs={10}>
              <input
                ref={fileInput}
                type='file'
                onChange={uploadFileCompanyImg}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyTitle}
                onChange={(e) => setCompanyTitle(e.target.value)}
                placeholder='Nombre Empresa'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder='Teléfono'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={companyInstagram}
                onChange={(e) => setCompanyInstagram(e.target.value)}
                placeholder='@ Instagram Empresa'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Correo'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder='Referencia'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='Comentario'
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Card>
                <InputPayments />
              </Card>
            </Grid> */}
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant='contained'
                onClick={() => onSubmit()}
              >Crear Cliente</Button>
              {error && <Typography variant='body1' color="red" style={{ marginTop: 20 }}>{error}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </RootForm>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
        message={message}
      />
    </Root>
  )
}

export default CreateClient