import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import Card from '../Cards/Card/Card'
import { ListModalRoot, RootButtons, RootTitle } from './CalendarModal.style'
import { DateCalendar } from '@mui/x-date-pickers'
import moment from 'moment'

const CalendarModal = ({ date, setDate, open, setOpen }) => {
    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <ListModalRoot>
          <Card>
            <Box padding={3}>
              <RootTitle>
                <Typography variant='h6' style={{ marginBottom: 50 }}>Calendar</Typography>
                {date && <Typography variant='h6' style={{ marginBottom: 50 }}>{moment(new Date(date)).format('DD/MM/YYYY')}</Typography>}
              </RootTitle>
              <DateCalendar value={date} onChange={(val) => setDate(val)} />
              <RootButtons>
                <Button onClick={() => setOpen(false)}>Cerrar</Button>
              </RootButtons>
            </Box>
          </Card>
        </ListModalRoot>
      </Modal>
    )
  }

export default CalendarModal