const dev = require('./dev')
const qa = require('./qa')
const prod = require('./prod')

const ENV = ['development', 'qa', 'production']

const NODE_ENV = 'production';

function handleObtain(val) {
  switch (val) {
    case ENV[0]:
      return dev
    case ENV[1]:
      return qa
    case ENV[2]:
      return prod
    default:
      return dev
  }
}

const send = handleObtain(NODE_ENV)
send.ENV = NODE_ENV
module.exports = send
